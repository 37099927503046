import {
  // addWatermark,
  arrayBufferToBase64,
  // generateThumbnail,
  heicToJpeg,
  resizeImage,
  // uploadFile,
} from 'routes/NewPost/utils'
import { GeoLocation } from 'types/GeoLocation.types'
import { getIsAuthenticated } from 'utils/auth'
import { Spinner } from 'flowbite-react'
import { useState } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import AccessDenied from 'routes/NewPost/components/AccessDenied'
import DropArea from 'routes/NewPost/components/DropArea/DropArea'
import FailedToAddPost from 'routes/NewPost/components/FailedToAddPost'
import ImageCropper from 'routes/NewPost/components/ImageCropper/ImageCropper'
import PostCreationProgress from 'routes/NewPost/components/PostCreationProgress'
import PostingGuidelines from 'routes/NewPost/components/PostingGuidelines'
// import round from 'lodash/round'
// import supabase from 'utils/supabase/client'
import UploadSuccesful from 'routes/NewPost/components/UploadSuccessful'

const NewPost = () => {
  const [droppedImage, setDroppedImage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | string[]>('')

  // const [geoLocation, setGeoLocation] = useState<GeoLocation | null>(null)

  const [isCreatingPost, setIsCreatingPost] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadPercentage, setUploadPercentage] = useState<number>(0)
  const [uploadWasSuccesful, setUploadWasSuccesful] = useState<boolean>(false)

  const onFileSelected = async (
    image: ArrayBuffer,
    imageGeoLocation: GeoLocation,
    mimeType: string
  ) => {
    setIsLoading(true)

    const jpegImage =
      mimeType === 'image/heic' ? await heicToJpeg(image) : image

    const base64Image = await arrayBufferToBase64(jpegImage)
    const resizedImage = await resizeImage(base64Image, 2000)

    // setGeoLocation(imageGeoLocation)
    console.log('imageGeoLocation', imageGeoLocation)

    setDroppedImage(resizedImage)

    setIsLoading(false)
  }

  // TODO Force user to use app for posting, or implement this.
  // const createPost = async (image: Blob) => {
  //   if (!image || !geoLocation) {
  //     setErrorMessage([
  //       'Failed to add post.',
  //       'The error has been reported and will be looked into.',
  //     ])

  //     // TODO Report.

  //     return
  //   }

  //   setIsCreatingPost(true)

  //   // Add watermark.

  //   let watermarkedImage

  //   try {
  //     watermarkedImage = await addWatermark(image)
  //   } catch (watermarkError) {
  //     setErrorMessage([
  //       'Failed to watermark image.',
  //       'The error has been reported and will be looked into.',
  //     ])

  //     // TODO Report 'error'.

  //     return
  //   }

  //   // Generate thumbnail.

  //   let thumbnail: Blob
  //   try {
  //     thumbnail = await generateThumbnail(watermarkedImage)
  //   } catch (error) {
  //     setErrorMessage([
  //       'Failed to generate thumbnail.',
  //       'The error has been reported and will be looked into.',
  //     ])

  //     // TODO Report 'error'.

  //     return
  //   }

  //   const filename = `${uuidv4()}.webp`

  //   // Create row in 'posts_to_review' table.

  //   const { error: databaseError } = await supabase()
  //     .from('posts_to_review')
  //     .insert({
  //       filename,
  //       location: `POINT(${geoLocation.longitude} ${geoLocation.latitude})`,
  //     })

  //   if (databaseError) {
  //     setErrorMessage([
  //       'Failed to create post in database.',
  //       'The error has been reported and will be looked into.',
  //     ])

  //     // TODO Report 'databaseError'.

  //     return
  //   }

  //   // Upload photo.

  //   setIsCreatingPost(false)
  //   setIsUploading(true)
  //   setUploadPercentage(0)

  //   const onProgress = (bytesUploaded: number, bytesTotal: number) => {
  //     setUploadPercentage(round((bytesUploaded / bytesTotal) * 100))
  //   }

  //   const onSuccess = () => {
  //     setUploadWasSuccesful(true)
  //   }

  //   const onImageUploadError = (message: string, error?: Error) => {
  //     setErrorMessage([
  //       message,
  //       'The error has been reported and will be looked into.',
  //     ])

  //     // TODO Report 'error'
  //     console.error(error)

  //     return
  //   }

  //   await uploadFile({
  //     filename,
  //     image: watermarkedImage,
  //     onImageUploadError,
  //     onProgress,
  //     onSuccess,
  //   })
  // }

  const onCropDone = (croppedImage: Blob) => {
    console.log('croppedImage', croppedImage)
  }
  // const onCropDone = (croppedImage: Blob) => {
  //   void createPost(croppedImage)
  // }

  const onError = (message: string | string[]) => setErrorMessage(message)

  const onResetPage = () => {
    setDroppedImage('')
    setErrorMessage('')
    // setGeoLocation(null)
    setIsCreatingPost(false)
    setIsUploading(false)
    setUploadPercentage(0)
    setUploadWasSuccesful(false)
  }

  if (!getIsAuthenticated()) {
    return <AccessDenied />
  } else if (errorMessage) {
    return <FailedToAddPost message={errorMessage} onTryAgain={onResetPage} />
  } else if (uploadWasSuccesful) {
    return <UploadSuccesful onResetPage={onResetPage} />
  }

  return (
    <>
      <h1 className="text-3xl text-center mt-8">New Post</h1>
      <div className="flex justify-center mt-12">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center">
            <Spinner className="mb-2" color="purple" size="lg" />
          </div>
        ) : isCreatingPost || isUploading ? (
          <PostCreationProgress
            isCreatingPost={isCreatingPost}
            isUploading={isUploading}
            uploadPercentage={uploadPercentage}
          />
        ) : droppedImage ? (
          <ImageCropper
            imageSrc={droppedImage}
            onCropDone={onCropDone}
            onError={onError}
          />
        ) : (
          <DropArea onFileSelected={onFileSelected} onError={onError} />
        )}
      </div>

      {!isLoading && !droppedImage && <PostingGuidelines />}
    </>
  )
}

export default NewPost
