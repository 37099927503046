import { Alert } from 'flowbite-react'
import { HiInformationCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import SignInForm from 'components/AuthForm'

const AccessDenied = () => {
  const navigate = useNavigate()

  const onSignedIn = () => {
    navigate(0)
  }

  return (
    <div className="flex flex-col mt-10">
      <div className="flex justify-center">
        <Alert className="w-fit" color="info" icon={HiInformationCircle}>
          You need to be signed in to create a new post.
        </Alert>
      </div>

      <SignInForm onSignedIn={onSignedIn} />
    </div>
  )
}

export default AccessDenied
