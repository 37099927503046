import { BLURRED_PHOTOS_BUCKET, ORIGINAL_PHOTOS_BUCKET } from 'global/variables'
import { Button } from 'flowbite-react'
import { HiHandThumbDown, HiHandThumbUp, HiNoSymbol } from 'react-icons/hi2'
import { PostPhoto } from 'types/PostPhoto'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FullscreenSpinner from 'components/FullscreenSpinner'
import ImageBlurTool from 'components/ImageBlurTool'
import isEmpty from 'lodash/isEmpty'
import supabase from 'utils/supabase/client'

interface PhotoToReview {
  blurredImage?: Blob
  isReviewed: boolean
  noBlurNeeded: boolean
  photo: PostPhoto
}

interface PendingPost {
  description: string | null
  id: string
  photos: PhotoToReview[]
}

const Review = () => {
  const navigate = useNavigate()

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [post, setPost] = useState<PendingPost | null>(null)
  const [totalNoOfPosts, setTotalNoOfPosts] = useState<number | null>(null)
  const [savingBlurredImages, setSavingBlurredImages] = useState<boolean>(false)

  const areAllPhotosReviewed =
    post?.photos.every((photoItem) => photoItem.isReviewed) ?? false

  const getNextPost = async () => {
    setIsLoading(true)

    const {
      data: { user },
    } = await supabase().auth.getUser()

    if (!user) {
      console.error('No user!')
      return
    } else {
      setIsAdmin(user.app_metadata.is_admin as boolean)
    }

    if (!user.app_metadata.is_admin) {
      return
    }

    // Fetch number of pending posts
    const { count } = await supabase()
      .from('posts')
      .select('*', { count: 'exact', head: true })
      .eq('status', 'pending')

    setTotalNoOfPosts(count)

    const { data: pendingPostData, error: pendingPostError } =
      await supabase().rpc('get_pending_posts', { p_limit: 1, p_offset: 0 })

    if (pendingPostError) {
      console.error('Error fetching pending post:', pendingPostError)
      setIsLoading(false)
      return
    }

    if (
      !pendingPostData ||
      pendingPostData.length === 0 ||
      isEmpty(pendingPostData[0])
    ) {
      setIsLoading(false)
      return
    }

    const pendingPost = pendingPostData[0]

    const { data: postPhotos, error: photosError } = await supabase().rpc(
      'get_post_photos',
      { p_post_id: pendingPost.id }
    )

    if (photosError) {
      console.error('Error fetching post photos:', photosError)
      setIsLoading(false)
      return
    }

    if (!postPhotos || postPhotos.length === 0) {
      console.error('No photos found for post')
      setIsLoading(false)
      return
    }

    const photosToReview = postPhotos.map((photo) => ({
      photo,
      isReviewed: false,
      noBlurNeeded: false,
    }))

    const postWithPhotos: PendingPost = {
      id: pendingPost.id,
      description: pendingPost.description,
      photos: photosToReview,
    }

    setPost(postWithPhotos)
    setIsLoading(false)
  }

  useEffect(() => {
    void getNextPost()
  }, [])

  const resetReview = () => {
    setPost(null)
    setIsLoading(true)
    setTotalNoOfPosts(null)
    setSavingBlurredImages(false)

    void getNextPost()
  }

  const handleSaveBlurredImage = (photoToReview: PhotoToReview, blob: Blob) => {
    // Update the photo with the blurred image blob and mark as reviewed
    if (post) {
      const updatedPhotos = post.photos.map((p) =>
        p.photo.id === photoToReview.photo.id
          ? {
              ...p,
              blurredImage: blob,
              isReviewed: true,
              noBlurNeeded: false,
            }
          : p
      )
      setPost({ ...post, photos: updatedPhotos })
    }
  }

  // Mark a photo as "No blur needed"
  const markNoBlurNeeded = (photoId: string) => {
    if (post) {
      const updatedPhotos = post.photos.map((p) =>
        p.photo.id === photoId
          ? {
              ...p,
              isReviewed: true,
              noBlurNeeded: true,
              blurredImage: undefined, // Clear any blur data
            }
          : p
      )
      setPost({ ...post, photos: updatedPhotos })
    }
  }

  // Clear all blur areas for a photo
  const clearBlurAreas = (photoId: string) => {
    if (post) {
      // Get the photo we need to clear
      const photoToReset = post.photos.find((p) => p.photo.id === photoId)

      if (!photoToReset?.photo.imageUrl) {
        return
      }

      // Re-fetch the original image
      const fetchOriginalImage = async () => {
        try {
          // Get the original URL from supabase again to refresh it
          const { data: image, error: imageError } = await supabase()
            .storage.from(ORIGINAL_PHOTOS_BUCKET)
            .createSignedUrl(photoToReset.photo.filename, 60)

          if (imageError) {
            console.error(
              `Failed to refresh image URL ${photoToReset.photo.filename}:`,
              imageError
            )
            return
          }

          // Update the photos array
          const updatedPhotos = post.photos.map((p) =>
            p.photo.id === photoId
              ? {
                  ...p,
                  blurredImage: undefined,
                  isReviewed: false,
                  noBlurNeeded: false,
                  photo: {
                    ...p.photo,
                    imageUrl: image.signedUrl, // Update with fresh URL
                  },
                }
              : p
          )

          // Update the post state with the refreshed photo
          setPost({ ...post, photos: updatedPhotos })
        } catch (error) {
          console.error('Error refreshing image:', error)
        }
      }

      // Execute the refresh
      void fetchOriginalImage()
    }
  }

  // Add watermark to an image
  const addWatermark = async (imageBlob: Blob): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        // Create a canvas with the same dimensions as the image
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')

        if (!ctx) {
          reject(new Error('Could not get canvas context'))
          return
        }

        // Draw the original image on the canvas
        ctx.drawImage(img, 0, 0)

        // Set up the watermark text
        ctx.font = '32px Arial'
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'
        ctx.textAlign = 'right'
        ctx.textBaseline = 'bottom'

        // Add the watermark text
        ctx.fillText('andshame.com', canvas.width - 30, canvas.height - 20)

        // Convert canvas back to blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            } else {
              reject(new Error('Failed to create watermarked image blob'))
            }
          },
          'image/jpeg',
          0.95
        )
      }

      img.onerror = () =>
        reject(new Error('Failed to load image for watermarking'))
      img.src = URL.createObjectURL(imageBlob)
    })
  }

  const saveAllBlurredImages = async () => {
    if (!post) return

    setSavingBlurredImages(true)

    try {
      // Upload each image to storage
      for (const photoToReview of post.photos) {
        if (photoToReview.blurredImage) {
          // Add watermark to the blurred image before uploading
          const watermarkedBlob = await addWatermark(photoToReview.blurredImage)

          const { error: uploadError } = await supabase()
            .storage.from(BLURRED_PHOTOS_BUCKET)
            .upload(photoToReview.photo.filename, watermarkedBlob, {
              upsert: true,
              contentType: 'image/jpeg',
            })

          if (uploadError) {
            console.error(
              `Failed to upload blurred image ${photoToReview.photo.filename}:`,
              uploadError
            )
            throw new Error(
              `Failed to upload blurred image: ${uploadError.message}`
            )
          }
        }

        // If no blur needed but photo is reviewed, upload original image to blurred bucket
        else if (photoToReview.noBlurNeeded && photoToReview.photo.imageUrl) {
          // Fetch the original image
          const response = await fetch(photoToReview.photo.imageUrl)
          const blob = await response.blob()

          // Add watermark to the original image before uploading to blurred bucket
          const watermarkedBlob = await addWatermark(blob)

          // Upload to blurred bucket (since no blurring was needed)
          const { error: uploadError } = await supabase()
            .storage.from(BLURRED_PHOTOS_BUCKET)
            .upload(photoToReview.photo.filename, watermarkedBlob, {
              upsert: true,
              contentType: 'image/jpeg',
            })

          if (uploadError) {
            console.error(
              `Failed to upload original image to blurred bucket ${photoToReview.photo.filename}:`,
              uploadError
            )
            throw new Error(
              `Failed to upload original image: ${uploadError.message}`
            )
          }
        }
      }

      // Now show success notification or continue with review flow
      console.log('All images processed and saved successfully')
    } catch (error) {
      console.error('Error saving images:', error)
    } finally {
      setSavingBlurredImages(false)
    }
  }

  const createAndUploadThumbnail = async () => {
    if (!post) return false

    try {
      // Find the primary photo (displayOrder === 0)
      const primaryPhoto = post.photos.find((p) => p.photo.displayOrder === 0)
      if (!primaryPhoto?.photo?.imageUrl) {
        console.error('Primary photo not found or has no image URL')
        return false
      }

      // Fetch the original image
      const response = await fetch(primaryPhoto.photo.imageUrl)
      if (!response.ok) {
        console.error('Failed to fetch original image for thumbnail')
        return false
      }

      const imageBlob = await response.blob()

      // Create a 200x200 thumbnail
      const img = new Image()
      const loadImagePromise = new Promise<void>((resolve, reject) => {
        img.onload = () => resolve()
        img.onerror = () =>
          reject(new Error('Failed to load image for thumbnail creation'))
        img.src = URL.createObjectURL(imageBlob)
      })

      await loadImagePromise

      const canvas = document.createElement('canvas')
      canvas.width = 200
      canvas.height = 200
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        console.error('Could not get canvas context')
        return false
      }

      // Draw the image resized to 200x200
      ctx.drawImage(img, 0, 0, 200, 200)

      // Convert canvas to blob
      const thumbnailBlob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.9)
      })

      if (!thumbnailBlob) {
        console.error('Failed to create thumbnail blob')
        return false
      }

      // Clean up the object URL
      URL.revokeObjectURL(img.src)

      // Upload thumbnail to storage
      const thumbnailPath = `thumbnails/${primaryPhoto.photo.filename}`
      const { error: uploadError } = await supabase()
        .storage.from(BLURRED_PHOTOS_BUCKET)
        .upload(thumbnailPath, thumbnailBlob, {
          upsert: true,
          contentType: 'image/jpeg',
        })

      if (uploadError) {
        console.error('Failed to upload thumbnail:', uploadError)
        return false
      }

      console.log(
        'Thumbnail created and uploaded successfully at',
        thumbnailPath
      )
      return true
    } catch (error) {
      console.error('Error creating or uploading thumbnail:', error)
      return false
    }
  }

  const onApprove = async () => {
    if (!post) {
      console.error('No post to approve.')
      return
    }

    // Check if all photos have been reviewed
    if (!areAllPhotosReviewed) {
      console.error('Not all photos have been reviewed yet.')
      return
    }

    setIsLoading(true)

    // Create a thumbnail of the primary photo
    const thumbnailSuccess = await createAndUploadThumbnail()
    if (!thumbnailSuccess) {
      alert('Failed to create or upload thumbnail. Please try again.')
      setIsLoading(false)
      return
    }

    // Upload all images to the blurred-photos bucket
    await saveAllBlurredImages()

    await supabase().functions.invoke('approve_post', {
      body: {
        postId: post.id,
      },
    })

    resetReview()
  }

  const onReject = async () => {
    if (!post) {
      console.error('No post to reject.')
      return
    }

    setIsLoading(true)

    await supabase().functions.invoke('reject_post', {
      body: {
        postId: post.id,
      },
    })

    resetReview()
  }

  const onBan = () => {
    console.log('onBan()')
  }

  if (isLoading || savingBlurredImages) {
    return <FullscreenSpinner />
  } else if (!isAdmin) {
    return <h1 className="text-3xl text-center mt-8">Nope!</h1>
  } else if (totalNoOfPosts === 0 || !post) {
    return (
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl text-center mt-8">Nothing to review</h1>
        <Button color="purple" className="mt-8" onClick={() => navigate('/')}>
          Home
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <Button
        color="dark"
        className="border-none"
        onClick={() => navigate('/')}
      >
        Home
      </Button>

      <h1 className="text-3xl text-center mt-8">Review</h1>
      <p className="text-center text-md text-gray-500">
        {`${totalNoOfPosts} in total`}
      </p>

      {/* Display metadata about the post */}
      <div className="mt-4 mb-6 bg-gray-700 p-4 rounded-lg max-w-4xl w-full">
        <p>
          <strong>Description:</strong> {post.description ?? 'No description'}
        </p>
        <p>
          <strong>Photos:</strong> {post.photos.length}
        </p>
      </div>

      {/* Display all photos with blur tools */}
      <div className="grid grid-cols-1 gap-8 mb-8 max-w-4xl w-full">
        {post.photos.map((photoToReview) => (
          <div
            key={photoToReview.photo.id}
            className="rounded-lg p-4 bg-gray-700 shadow-sm"
          >
            {photoToReview.photo.displayOrder === 0 && (
              <h3 className="text-lg font-semibold mb-2">
                Primary Photo and thumbnail
              </h3>
            )}

            {photoToReview.photo.imageUrl && (
              <div className="container mx-auto p-0">
                <ImageBlurTool
                  imageSrc={photoToReview.photo.imageUrl}
                  onSave={(blob) => handleSaveBlurredImage(photoToReview, blob)}
                />
                <div className="mt-4 flex flex-wrap items-center gap-3">
                  {/* Show status based on review state */}
                  {photoToReview.isReviewed ? (
                    <div className="bg-green-100 text-green-800 p-2 rounded-md">
                      {photoToReview.noBlurNeeded
                        ? '✓ No blur needed'
                        : '✓ Blurred'}
                    </div>
                  ) : (
                    <Button
                      color="blue"
                      onClick={() => markNoBlurNeeded(photoToReview.photo.id)}
                    >
                      No blur needed
                    </Button>
                  )}

                  {/* Show clear button only if the photo has been blurred */}
                  {photoToReview.blurredImage && (
                    <Button
                      color="gray"
                      onClick={() => clearBlurAreas(photoToReview.photo.id)}
                    >
                      Clear all blur areas
                    </Button>
                  )}
                </div>
              </div>
            )}

            {!photoToReview.photo.imageUrl && (
              <div className="bg-gray-200 p-6 text-center rounded">
                Failed to load this image
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex size-full justify-between max-w-4xl">
        <Button color="failure" className="" onClick={onBan}>
          <HiNoSymbol className="size-5 mr-2" />
          Ban
        </Button>

        <Button color="warning" className="" onClick={onReject}>
          <HiHandThumbDown className="size-5 mr-2" />
          No
        </Button>

        <Button
          color="success"
          className=""
          onClick={onApprove}
          disabled={!areAllPhotosReviewed}
        >
          <HiHandThumbUp className="size-5 mr-2" />
          Yes
        </Button>
      </div>
    </div>
  )
}

export default Review
