import { CheckCircleIcon, NoSymbolIcon } from '@heroicons/react/16/solid'

const PostingGuidelines = () => {
  const guidelines = {
    allowed: {
      one: {
        description:
          'Keep it humorous, not hateful. Focus on actions, not insults.',
        title: 'Playful Call-Outs',
      },
      three: {
        description:
          'Only share photos you took yourself or have permission to post.',
        title: 'Respect Photo Rights',
      },
      title: "What's Allowed",
      two: {
        description: 'No private settings or personal moments.',
        title: 'Public Behavior Only',
      },
    },
    prohibited: {
      one: {
        description: 'No insults, slurs, or targeting personal traits.',
        title: 'Bullying or Hate',
      },
      three: {
        description:
          'No violence, nudity, threats or content featuring minors.',
        title: 'Harmful Content',
      },
      title: "What's Not Allowed",
      two: {
        description:
          'No names, addresses, or anything that could identify someone.',
        title: 'Private Info',
      },
    },
  }

  return (
    <div className="flex flex-col items-center mt-12">
      <h4 className="text-md text-center text-gray-300 sm:text-3xl">
        What to post
      </h4>

      <div className="w-fit mt-8 mx-8">
        <div className="flex items-center">
          <CheckCircleIcon className="mr-2 size-6 text-green-500" />
          <h4 className="font-semibold text-md text-center text-green-500 sm:text-3xl">
            {guidelines.allowed.title}
          </h4>
        </div>

        <ul className="ml-2.5 mt-2 max-w-md space-y-4 text-green-500 list-inside dark:text-green-400">
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.allowed.one.title}
            </span>
            <span>{guidelines.allowed.one.description}</span>
          </li>
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.allowed.two.title}
            </span>
            <span>{guidelines.allowed.two.description}</span>
          </li>
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.allowed.three.title}
            </span>
            <span>{guidelines.allowed.three.description}</span>
          </li>
        </ul>

        <div className="flex items-center mt-8">
          <NoSymbolIcon className="mr-2 size-6 text-red-500" />
          <h4 className="font-semibold text-md text-center text-red-500 sm:text-3xl">
            {guidelines.prohibited.title}
          </h4>
        </div>

        <ul className="ml-2.5 mt-2 max-w-md space-y-4 text-red-500 list-inside dark:text-red-400">
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.prohibited.one.title}
            </span>
            <span>{guidelines.prohibited.one.description}</span>
          </li>
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.prohibited.two.title}
            </span>
            <span>{guidelines.prohibited.two.description}</span>
          </li>
          <li className="flex flex-col">
            <span className="font-semibold">
              {guidelines.prohibited.three.title}
            </span>
            <span>{guidelines.prohibited.three.description}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default PostingGuidelines
