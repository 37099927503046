// import { Button, Spinner } from 'flowbite-react'
// import { getUser, getIsAuthenticated } from 'utils/auth'
// import { showToast } from 'utils/toast'
// import { useEffect, useState } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
// import SignInModal from 'components/SignInModal'
// import VotingIndicator from 'routes/ViewPost/components/VotingButtons/VotingIndicator'

// import {
//   getUserCastVote,
//   getVotesSummary,
//   voteAgree,
//   voteDisagree,
//   withdrawVote,
// } from './utils'

// type VotesSummary = {
//   agree: number
//   disagree: number
// }

const VotingButtons = ({ postId }: { postId: string }) => {
  console.log('postId', postId)

  // const location = useLocation()
  // const navigate = useNavigate()

  // const [votesSummary, setVotesSummary] = useState<VotesSummary | null>(null)
  // const [isLoadingVotesSummary, setIsLoadingVotesSummary] = useState(true)

  // const [userCastVote, setUserCastVote] = useState<string | null>(null)
  // const [isLoadingUserCastVote, setIsLoadingUserCastVote] = useState(true)

  // const [shouldShowSignInModal, setShouldShowSignInModal] =
  //   useState<boolean>(false)

  // useEffect(() => {
  //   // Votes summary

  //   const fetchData = async () => {
  //     if (!postId) {
  //       return
  //     }

  //     const { votesSummary, error } = await getVotesSummary(postId)

  //     if (error) {
  //       showToast('Failed to get votes summary.', 'error')

  //       // TODO Report error
  //       console.error(error)
  //     } else {
  //       setVotesSummary(votesSummary)
  //     }
  //     setIsLoadingVotesSummary(false)
  //   }

  //   void fetchData()
  // }, [postId, userCastVote])

  // useEffect(() => {
  // Check what user already voted.

  // const fetchData = async () => {
  //   const isAuthenticated = getIsAuthenticated()

  //   if (!postId || !isAuthenticated) {
  //     setIsLoadingUserCastVote(false)
  //     return
  //   }

  //   const user = getUser()

  //   if (!user) {
  //     setIsLoadingUserCastVote(false)
  //     showToast('Failed to get cast vote.', 'error')
  //     return
  //   }

  //   const { vote, error } = await getUserCastVote(postId, user.id)

  //   if (error) {
  //     showToast('Failed to get user cast vote.', 'error')

  //     // TODO Report error
  //     console.error(error)
  //   } else {
  //     setUserCastVote(vote)
  //   }

  //   setIsLoadingUserCastVote(false)
  // }

  // void fetchData()
  // }, [postId, userCastVote])

  // const onVoteAgree = async () => {
  // TODO Should work like in the app.

  // const isAuthenticated = getIsAuthenticated()

  // if (!isAuthenticated) {
  //   setShouldShowSignInModal(true)
  //   return
  // }

  // if (!postId) {
  //   console.error('Trying to vote but no post ID available')
  //   return
  // }

  // if (userCastVote === 'agree') {
  //   await withdrawVote(postId)
  //   setUserCastVote(null)
  // } else if (userCastVote === 'disagree') {
  //   await withdrawVote(postId)
  //   await voteAgree(postId)
  //   setUserCastVote('agree')
  // } else if (!userCastVote) {
  //   await voteAgree(postId)
  //   setUserCastVote('agree')
  // }
  // }

  // const onVoteDisagree = async () => {
  // TODO Should work like in the app.

  // const isAuthenticated = getIsAuthenticated()

  // if (!isAuthenticated) {
  //   setShouldShowSignInModal(true)
  //   return
  // }

  // if (!postId) {
  //   console.error('Trying to vote but no post ID available')
  //   return
  // }

  // if (userCastVote === 'disagree') {
  //   await withdrawVote(postId)
  //   setUserCastVote(null)
  // } else if (userCastVote === 'agree') {
  //   await withdrawVote(postId)
  //   await voteDisagree(postId)
  //   setUserCastVote('disagree')
  // } else if (!userCastVote) {
  //   await voteDisagree(postId)
  //   setUserCastVote('disagree')
  // }
  // }

  // const onCloseSignInModal = () => {
  //   setShouldShowSignInModal(false)
  // }

  // const onSignedIn = () => {
  //   setShouldShowSignInModal(false)

  //   navigate(location.pathname)
  // }

  // const isLoading = isLoadingVotesSummary || isLoadingUserCastVote

  return null

  // return (
  //   <div className="flex flex-col items-center">
  //     <p className="mb-2 text-sm">
  //       {userCastVote === 'agree' ? (
  //         <span>
  //           You voted <span className="italic">Yes!</span>
  //         </span>
  //       ) : userCastVote === 'disagree' ? (
  //         <span>
  //           You voted <span className="italic">No</span>
  //         </span>
  //       ) : (
  //         'Shameful?'
  //       )}
  //     </p>
  //     <div className="flex mb-6">
  //       <Button
  //         className="mx-3 w-20"
  //         color={userCastVote === 'agree' ? 'light' : 'success'}
  //         disabled={isLoading}
  //         onClick={onVoteDisagree}
  //       >
  //         {isLoadingUserCastVote ? <Spinner size="sm" /> : 'No'}
  //       </Button>

  //       <Button
  //         className="mx-3 w-20"
  //         color={userCastVote === 'disagree' ? 'light' : 'failure'}
  //         disabled={isLoading}
  //         onClick={onVoteAgree}
  //       >
  //         {isLoadingUserCastVote ? <Spinner size="sm" /> : 'Yes!'}
  //       </Button>
  //     </div>

  //     {votesSummary && (
  //       <VotingIndicator
  //         noOfAgreeVotes={votesSummary?.agree}
  //         noOfDisagreeVotes={votesSummary?.disagree}
  //       />
  //     )}

  //     <SignInModal
  //       onClose={onCloseSignInModal}
  //       shouldShow={shouldShowSignInModal}
  //       onSignedIn={onSignedIn}
  //     />
  //   </div>
  // )
}

export default VotingButtons
