import React, { useState, useRef, useEffect, useCallback } from 'react'

// Define the prop types
interface ImageBlurToolProps {
  imageSrc: string
  onSave: (blob: Blob) => void
}

// Define the blur area type
interface BlurArea {
  x: number
  y: number
  width: number
  height: number
}

// Define the image size type
interface ImageSize {
  width: number
  height: number
}

const ImageBlurTool: React.FC<ImageBlurToolProps> = ({ imageSrc, onSave }) => {
  const [blurAreas, setBlurAreas] = useState<BlurArea[]>([])
  const [isDrawing, setIsDrawing] = useState<boolean>(false)
  const [currentArea, setCurrentArea] = useState<BlurArea | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 1000,
    height: 1000,
  })
  const [scale, setScale] = useState<number>(1)
  const [error, setError] = useState<string | null>(null)
  const [localImageSrc, setLocalImageSrc] = useState<string | null>(null)

  const applyBlurToArea = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      area: BlurArea,
      isPreview = false
    ): void => {
      // Round to integer values to prevent sub-pixel issues
      const x = Math.floor(area.x)
      const y = Math.floor(area.y)
      const width = Math.floor(area.width)
      const height = Math.floor(area.height)

      // Ensure we don't try to get image data outside the canvas boundaries
      const safeX = Math.max(0, x)
      const safeY = Math.max(0, y)
      const safeWidth = Math.min(ctx.canvas.width - safeX, width)
      const safeHeight = Math.min(ctx.canvas.height - safeY, height)

      // Skip if dimensions are invalid or too small
      if (safeWidth <= 1 || safeHeight <= 1) {
        // If area is too small, just draw a visual indicator
        ctx.fillStyle = 'rgba(150, 150, 150, 0.3)'
        ctx.beginPath()
        ctx.ellipse(
          safeX + safeWidth / 2,
          safeY + safeHeight / 2,
          Math.max(1, safeWidth / 2),
          Math.max(1, safeHeight / 2),
          0,
          0,
          Math.PI * 2
        )
        ctx.fill()
        return
      }

      // For preview in the editor, always show just the oval indicator
      if (isPreview) {
        // Create a visual ellipse indicator instead
        ctx.fillStyle = 'rgba(150, 150, 150, 0.5)'
        ctx.beginPath()
        ctx.ellipse(
          safeX + safeWidth / 2,
          safeY + safeHeight / 2,
          safeWidth / 2,
          safeHeight / 2,
          0,
          0,
          Math.PI * 2
        )
        ctx.fill()
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)'
        ctx.lineWidth = 2
        ctx.beginPath()
        ctx.ellipse(
          safeX + safeWidth / 2,
          safeY + safeHeight / 2,
          safeWidth / 2,
          safeHeight / 2,
          0,
          0,
          Math.PI * 2
        )
        ctx.stroke()
        return
      }

      // Only apply actual blur when saving the final image (not in preview)
      try {
        // Create a temporary canvas to hold the oval mask
        const tempCanvas = document.createElement('canvas')
        tempCanvas.width = safeWidth
        tempCanvas.height = safeHeight
        const tempCtx = tempCanvas.getContext('2d')

        if (!tempCtx) return

        // Draw the original image portion to the temp canvas
        tempCtx.drawImage(
          ctx.canvas,
          safeX,
          safeY,
          safeWidth,
          safeHeight,
          0,
          0,
          safeWidth,
          safeHeight
        )

        // Get the image data for blurring
        const imageData = tempCtx.getImageData(0, 0, safeWidth, safeHeight)
        const pixels = imageData.data

        // Create elliptical mask
        const ellipseX = safeWidth / 2
        const ellipseY = safeHeight / 2
        const radiusX = safeWidth / 2
        const radiusY = safeHeight / 2

        // Apply efficient blur with fixed radius
        const blurRadius = 8
        const downSampleFactor = safeWidth > 100 ? 2 : 1

        for (let i = 0; i < safeHeight; i += downSampleFactor) {
          for (let j = 0; j < safeWidth; j += downSampleFactor) {
            // Calculate if the pixel is inside the ellipse
            const normalizedX = (j - ellipseX) / radiusX
            const normalizedY = (i - ellipseY) / radiusY
            const isInsideEllipse =
              normalizedX * normalizedX + normalizedY * normalizedY <= 1

            // Only blur pixels inside the ellipse
            if (isInsideEllipse) {
              let r = 0,
                g = 0,
                b = 0,
                a = 0,
                count = 0
              const sampleStep = Math.max(1, Math.floor(blurRadius / 3))

              // Use larger steps for sampling (skip pixels)
              for (let ky = -blurRadius; ky <= blurRadius; ky += sampleStep) {
                for (let kx = -blurRadius; kx <= blurRadius; kx += sampleStep) {
                  const posX = Math.min(safeWidth - 1, Math.max(0, j + kx))
                  const posY = Math.min(safeHeight - 1, Math.max(0, i + ky))
                  const idx = (posY * safeWidth + posX) * 4

                  if (idx >= 0 && idx < pixels.length - 3) {
                    r += pixels[idx]
                    g += pixels[idx + 1]
                    b += pixels[idx + 2]
                    a += pixels[idx + 3]
                    count++
                  }
                }
              }

              // Calculate average
              if (count > 0) {
                const avgR = r / count
                const avgG = g / count
                const avgB = b / count
                const avgA = a / count

                // Apply to this pixel and neighbors if using downsampling
                for (
                  let di = 0;
                  di < downSampleFactor && i + di < safeHeight;
                  di++
                ) {
                  for (
                    let dj = 0;
                    dj < downSampleFactor && j + dj < safeWidth;
                    dj++
                  ) {
                    const idx = ((i + di) * safeWidth + (j + dj)) * 4
                    if (idx >= 0 && idx < pixels.length - 3) {
                      pixels[idx] = avgR
                      pixels[idx + 1] = avgG
                      pixels[idx + 2] = avgB
                      pixels[idx + 3] = avgA
                    }
                  }
                }
              }
            }
          }
        }

        // Put the blurred image data back to temp canvas
        tempCtx.putImageData(imageData, 0, 0)

        // Draw the temp canvas with blurred ellipse back to main canvas
        ctx.drawImage(tempCanvas, safeX, safeY)
      } catch (error) {
        // If we still have CORS issues, fallback to a visual indicator
        console.error('Failed to apply blur effect:', error)

        // Create a visual ellipse indicator instead
        ctx.fillStyle = 'rgba(150, 150, 150, 0.5)'
        ctx.beginPath()
        ctx.ellipse(
          safeX + safeWidth / 2,
          safeY + safeHeight / 2,
          safeWidth / 2,
          safeHeight / 2,
          0,
          0,
          Math.PI * 2
        )
        ctx.fill()
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)'
        ctx.lineWidth = 2
        ctx.beginPath()
        ctx.ellipse(
          safeX + safeWidth / 2,
          safeY + safeHeight / 2,
          safeWidth / 2,
          safeHeight / 2,
          0,
          0,
          Math.PI * 2
        )
        ctx.stroke()
      }
    },
    []
  )

  // Draw the image and all blur areas
  const drawImage = useCallback(
    (isPreviewMode = true): void => {
      if (!canvasRef.current || !imageRef.current) return

      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')

      if (!ctx) return

      // Set canvas size based on image and scale
      canvas.width = imageSize.width * scale
      canvas.height = imageSize.height * scale

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw the original image
      ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height)

      // Apply blur to each area
      try {
        blurAreas.forEach((area) => {
          // Apply blur with the scale factor
          const scaledArea = {
            x: area.x * scale,
            y: area.y * scale,
            width: area.width * scale,
            height: area.height * scale,
          }
          // Always pass isPreviewMode=true for the editor display
          applyBlurToArea(ctx, scaledArea, isPreviewMode)
        })
      } catch (e) {
        setError(
          'Error applying blur effect. This may be due to browser security restrictions.'
        )
      }
    },
    [blurAreas, imageSize.width, imageSize.height, scale, applyBlurToArea]
  )

  // Create a local copy of the image to avoid CORS issues
  useEffect(() => {
    let cleanupFunction: (() => void) | undefined

    // Reset blur areas when image source changes
    setBlurAreas([])

    const createLocalCopy = async () => {
      try {
        // Fetch the image as a blob
        const response = await fetch(imageSrc, { mode: 'cors' })
        const blob = await response.blob()

        // Create a local object URL
        const objectUrl = URL.createObjectURL(blob)
        setLocalImageSrc(objectUrl)

        // Set the cleanup function
        cleanupFunction = () => {
          if (objectUrl) URL.revokeObjectURL(objectUrl)
        }
      } catch (err) {
        setError(
          'Failed to create local copy of the image. Using direct source instead.'
        )
        setLocalImageSrc(imageSrc)
      }
    }

    // Execute the async function and properly handle the promise
    void createLocalCopy()

    // Return the cleanup function
    return () => {
      if (cleanupFunction) {
        cleanupFunction()
      }
    }
  }, [imageSrc])

  // Load the image and set up the canvas once we have a local copy
  useEffect(() => {
    if (!localImageSrc) return

    const img = new Image()

    img.onload = () => {
      setImageLoaded(true)
      setImageSize({ width: img.width, height: img.height })
      imageRef.current = img

      // Draw the image after it's loaded
      if (canvasRef.current) {
        drawImage()
      }
    }

    img.onerror = () => {
      setError('Failed to load image.')
    }

    img.src = localImageSrc
  }, [drawImage, localImageSrc])

  // Adjust scale when container size changes
  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth
        const newScale = Math.min(1, containerWidth / imageSize.width)
        setScale(newScale)
      }
    })

    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect()
  }, [containerRef, imageSize.width])

  // Redraw the canvas when scale or blur areas change
  useEffect(() => {
    if (imageLoaded) {
      drawImage()
    }
  }, [scale, blurAreas, imageLoaded, drawImage])

  // Draw preview oval without blur
  const drawPreviewOval = useCallback(
    (area: BlurArea): void => {
      if (!canvasRef.current) return
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      if (!ctx) return

      // Calculate dimensions for the oval
      const x = area.x < 0 ? 0 : area.x * scale
      const y = area.y < 0 ? 0 : area.y * scale
      const width = Math.abs(area.width * scale)
      const height = Math.abs(area.height * scale)

      // Draw dashed oval outline
      ctx.beginPath()
      ctx.ellipse(
        x + width / 2,
        y + height / 2,
        width / 2,
        height / 2,
        0,
        0,
        Math.PI * 2
      )
      ctx.setLineDash([5, 5])
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)'
      ctx.lineWidth = 2
      ctx.stroke()
      ctx.setLineDash([])
    },
    [scale]
  )

  // Handle mouse down to start drawing
  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>): void => {
    if (!canvasRef.current) return

    setIsDrawing(true)

    const rect = canvasRef.current.getBoundingClientRect()
    const x = (e.clientX - rect.left) / scale
    const y = (e.clientY - rect.top) / scale

    const newArea: BlurArea = { x, y, width: 0, height: 0 }
    setCurrentArea(newArea)
  }

  // Handle mouse move to update the current area size
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>): void => {
    if (!isDrawing || !currentArea || !canvasRef.current) return

    const rect = canvasRef.current.getBoundingClientRect()
    const x = (e.clientX - rect.left) / scale
    const y = (e.clientY - rect.top) / scale

    const width = x - currentArea.x
    const height = y - currentArea.y

    // Update current area
    const updatedArea = {
      ...currentArea,
      width,
      height,
    }
    setCurrentArea(updatedArea)

    // Redraw the image to clear previous preview
    drawImage()

    // Draw only the preview oval (no blur during drawing)
    if (Math.abs(width) > 0 && Math.abs(height) > 0) {
      const previewArea = {
        x: width < 0 ? currentArea.x + width : currentArea.x,
        y: height < 0 ? currentArea.y + height : currentArea.y,
        width: Math.abs(width),
        height: Math.abs(height),
      }

      drawPreviewOval(previewArea)
    }
  }

  // Handle mouse up to finish drawing
  const handleMouseUp = (): void => {
    if (!isDrawing || !currentArea) return

    setIsDrawing(false)

    // Only add the area if it has a valid size
    if (Math.abs(currentArea.width) > 10 && Math.abs(currentArea.height) > 10) {
      // Normalize the area (ensure width and height are positive)
      const normalizedArea: BlurArea = {
        x:
          currentArea.width < 0
            ? currentArea.x + currentArea.width
            : currentArea.x,
        y:
          currentArea.height < 0
            ? currentArea.y + currentArea.height
            : currentArea.y,
        width: Math.abs(currentArea.width),
        height: Math.abs(currentArea.height),
      }

      // Store the normalized area (without scaling applied)
      const newBlurAreas = [...blurAreas, normalizedArea]
      setBlurAreas(newBlurAreas)

      // Automatically save the image with blur areas
      saveImage(newBlurAreas)
    }

    setCurrentArea(null)

    // Apply blur after releasing mouse - now will respect the showFullBlur setting
    drawImage(true) // Always show preview mode in editor
  }

  // Apply all blurs and save the final image - this is where real blur happens
  const saveImage = (currentBlurAreas: BlurArea[] = blurAreas): void => {
    if (!canvasRef.current || !imageRef.current) return

    try {
      // Create a temporary canvas at full image resolution
      const tempCanvas = document.createElement('canvas')
      tempCanvas.width = imageSize.width
      tempCanvas.height = imageSize.height
      const tempCtx = tempCanvas.getContext('2d')

      if (!tempCtx) return

      // Draw the original image
      tempCtx.drawImage(
        imageRef.current,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      )

      // Apply blur to each area at full resolution
      currentBlurAreas.forEach((area) => {
        // Round to integer values
        const x = Math.floor(area.x)
        const y = Math.floor(area.y)
        const width = Math.floor(area.width)
        const height = Math.floor(area.height)

        // Ensure we don't try to get image data outside the canvas boundaries
        const safeX = Math.max(0, x)
        const safeY = Math.max(0, y)
        const safeWidth = Math.min(tempCanvas.width - safeX, width)
        const safeHeight = Math.min(tempCanvas.height - safeY, height)

        // Skip if dimensions are invalid or too small
        if (safeWidth <= 1 || safeHeight <= 1) {
          // For very small areas, just use solid gray ellipse
          tempCtx.fillStyle = 'rgba(150, 150, 150, 1)'
          tempCtx.beginPath()
          tempCtx.ellipse(
            safeX + safeWidth / 2,
            safeY + safeHeight / 2,
            Math.max(1, safeWidth / 2),
            Math.max(1, safeHeight / 2),
            0,
            0,
            Math.PI * 2
          )
          tempCtx.fill()
          return
        }

        try {
          // Create a temporary canvas for the oval mask
          const maskCanvas = document.createElement('canvas')
          maskCanvas.width = safeWidth
          maskCanvas.height = safeHeight
          const maskCtx = maskCanvas.getContext('2d')

          if (!maskCtx) return

          // Draw the original image portion to the mask canvas
          maskCtx.drawImage(
            tempCanvas,
            safeX,
            safeY,
            safeWidth,
            safeHeight,
            0,
            0,
            safeWidth,
            safeHeight
          )

          // Get the image data for blurring
          const imageData = maskCtx.getImageData(0, 0, safeWidth, safeHeight)
          const pixels = imageData.data
          const tempPixels = new Uint8ClampedArray(pixels)
          const blurRadius = 10

          // Apply blur algorithm to oval area
          for (let i = 0; i < safeHeight; i++) {
            for (let j = 0; j < safeWidth; j++) {
              // Calculate if the pixel is inside the ellipse
              const ellipseX = safeWidth / 2
              const ellipseY = safeHeight / 2
              const radiusX = safeWidth / 2
              const radiusY = safeHeight / 2

              // Normalize position relative to ellipse center
              const normalizedX = (j - ellipseX) / radiusX
              const normalizedY = (i - ellipseY) / radiusY

              // Check if point is inside ellipse (x²/a² + y²/b² <= 1)
              const isInsideEllipse =
                normalizedX * normalizedX + normalizedY * normalizedY <= 1

              // Only blur pixels inside the ellipse
              if (isInsideEllipse) {
                let r = 0,
                  g = 0,
                  b = 0,
                  a = 0,
                  count = 0

                for (let ky = -blurRadius; ky <= blurRadius; ky++) {
                  for (let kx = -blurRadius; kx <= blurRadius; kx++) {
                    const posX = Math.min(safeWidth - 1, Math.max(0, j + kx))
                    const posY = Math.min(safeHeight - 1, Math.max(0, i + ky))
                    const idx = (posY * safeWidth + posX) * 4

                    if (idx >= 0 && idx < tempPixels.length - 3) {
                      r += tempPixels[idx]
                      g += tempPixels[idx + 1]
                      b += tempPixels[idx + 2]
                      a += tempPixels[idx + 3]
                      count++
                    }
                  }
                }

                if (count > 0) {
                  const idx = (i * safeWidth + j) * 4
                  if (idx >= 0 && idx < pixels.length - 3) {
                    pixels[idx] = r / count
                    pixels[idx + 1] = g / count
                    pixels[idx + 2] = b / count
                    pixels[idx + 3] = a / count
                  }
                }
              }
            }
          }

          // Put the blurred image data back to mask canvas
          maskCtx.putImageData(imageData, 0, 0)

          // Draw the mask canvas back to the main canvas
          tempCtx.drawImage(maskCanvas, safeX, safeY)
        } catch (e) {
          // If we still have CORS issues with the final save, use a visual indicator
          console.error('Failed to apply blur during save:', e)
          tempCtx.fillStyle = 'rgba(150, 150, 150, 1)'
          tempCtx.beginPath()
          tempCtx.ellipse(
            safeX + safeWidth / 2,
            safeY + safeHeight / 2,
            safeWidth / 2,
            safeHeight / 2,
            0,
            0,
            Math.PI * 2
          )
          tempCtx.fill()
        }
      })

      // Convert canvas to blob and save
      tempCanvas.toBlob(
        (blob) => {
          if (onSave && blob) {
            onSave(blob)
          }
        },
        'image/jpeg',
        0.95
      )
    } catch (e) {
      setError('Failed to save image with blur. Please try a different image.')
      console.error('Error during save:', e)
    }
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          <p>{error}</p>
        </div>
      )}

      <div
        ref={containerRef}
        className="relative bg-gray-100 rounded-lg overflow-hidden"
        style={{ maxWidth: '100%' }}
      >
        <canvas
          ref={canvasRef}
          className="cursor-crosshair"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          style={{
            maxWidth: '100%',
            display: imageLoaded ? 'block' : 'none',
          }}
        />
        {!imageLoaded && !error && (
          <div className="flex items-center justify-center p-12 text-gray-500">
            Loading image...
          </div>
        )}
      </div>

      <div className="flex flex-wrap items-center gap-4 my-2">
        {blurAreas.length > 0 && (
          <div className="text-sm text-gray-300">
            Draw over faces to blur them. {blurAreas.length} blur area(s)
            created.
          </div>
        )}
      </div>
    </div>
  )
}

const MemoizedImageBlurTool = React.memo(ImageBlurTool)

export default MemoizedImageBlurTool
