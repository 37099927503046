import { Blockquote } from 'flowbite-react'
// import { BoundingBox } from 'types/BoundingBox.types'
import { GeoLocationNumber } from 'types/GeoLocationNumber.types'
// import { PostInView } from 'types/PostInView.types'
// import { showToast } from 'utils/toast'
import { useEffect } from 'react'
import { useSessionStorage } from '@uidotdev/usehooks'
// import Drawer from 'components/Drawer'
import ErrorMessage from 'routes/Home/components/ErrorMessage'
// import Map from 'components/Map'
import supabase from 'utils/supabase/client'

import 'react-modern-drawer/dist/index.css'
// import { LatLng } from 'leaflet'

const Home = () => {
  // const [isDrawerShowing, setIsDrawerShowing] = useState<boolean>(false)
  const [geolocation, setGeolocation] = useSessionStorage('', '')

  // Hiding map for now.
  // const posts: PostInView[] = []
  // const [posts, setPosts] = useState<PostInView[]>([])

  // Hiding map for now.
  // const [previewedPost, setPreviewedPost] = useState<PostInView | undefined>(
  //   undefined
  // )

  const params = new URLSearchParams(window.location.hash.slice())

  // const toggleDrawer = () => {
  //   setIsDrawerShowing(!isDrawerShowing)
  // }

  useEffect(() => {
    const geolocationLookup = async () => {
      if (geolocation) {
        // Geolocation has already been stored in session storage.
        return
      }

      const {
        data,
        error,
      }: {
        data: GeoLocationNumber | null
        error: Error | null
      } = await supabase().functions.invoke('geolocation_lookup')

      if (error ?? !data) {
        // TODO Handle error
        console.log('error', error)
      }

      // Use Hyde Park if user's location can't be found.
      setGeolocation(
        JSON.stringify({
          latitude: !error && data ? data.latitude : 51.512767,
          longitude: !error && data ? data.longitude : -0.164722,
        })
      )
    }

    void geolocationLookup()
  }, [geolocation, setGeolocation])

  // Hiding map for now.
  // const fetchPosts = (boundingBox: BoundingBox) => {
  //   // TODO Disabled for now.
  //   console.log('Not looking for posts at', boundingBox)
  //   // const { data, error } = await supabase().rpc('entries_in_view', boundingBox)

  //   // if (error) {
  //   //   showToast('Failed to get posts.', 'error')

  //   //   // TODO report error.
  //   // } else if (data) {
  //   //   setPosts(data)
  //   // }
  // }

  // Hiding map for now.
  // const previewPost = (post: PostInView) => {
  //   setPreviewedPost(post)
  //   setIsDrawerShowing(true)
  // }

  // Hiding map for now.
  // const onBoundingBoxChanged = (latLng: LatLng, boundingBox: BoundingBox) => {
  //   setGeolocation(
  //     JSON.stringify({
  //       latitude: latLng.lat,
  //       longitude: latLng.lng,
  //     })
  //   )

  //   void fetchPosts(boundingBox)
  // }

  if (params.get('error_code')) {
    return <ErrorMessage message={params.get('error_description') ?? ''} />
  }

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <Blockquote className="mb-8">See it. Shame it. Sorted.</Blockquote>

      {/* {geolocation && (
        <Map
          geolocation={JSON.parse(geolocation)}
          onBoundingBoxChanged={onBoundingBoxChanged}
          onPreviewPost={previewPost}
          posts={posts}
        />
      )} */}

      {/* {previewedPost && (
        <Drawer
          post={previewedPost}
          isShowing={isDrawerShowing}
          onClose={toggleDrawer}
        />
      )} */}
    </div>
  )
}

export default Home
