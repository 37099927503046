import ReactMarkdown from 'react-markdown'
import termsOfUse from 'assets/2025-02-19-terms-of-use.md?raw'

const TermsOfUse = () => (
  <div className="flex flex-col items-center mt-10 mx-4">
    <article className="prose dark:prose-invert">
      <ReactMarkdown>{termsOfUse}</ReactMarkdown>
    </article>
  </div>
)

export default TermsOfUse
