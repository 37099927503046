import { useNavigate } from 'react-router-dom'
import AuthForm from 'components/AuthForm'

const SignIn = () => {
  const navigate = useNavigate()

  const onSignedIn = () => {
    navigate('/')
  }

  return <AuthForm onSignedIn={onSignedIn} />
}

export default SignIn
