import { NavigateFunction } from 'react-router-dom'
import { SB_PROJECT_ID } from 'global/variables'
import { User } from '@supabase/supabase-js'
import supabase from 'utils/supabase/client'

const LOCALSTORAGE_USER_KEY = `sb-${SB_PROJECT_ID}-auth-token`

export const getIsAuthenticated = () => {
  try {
    const value = window.localStorage.getItem(LOCALSTORAGE_USER_KEY)

    if (value) {
      const auth = JSON.parse(value) as { user: User }

      return !!auth.user
    }
  } catch (error) {
    // TODO Handle localstorage not being available.
    console.error(error)
  }

  return false
}

export const getUser = (): User | null => {
  try {
    const value = window.localStorage.getItem(LOCALSTORAGE_USER_KEY)

    if (value) {
      const auth = JSON.parse(value) as { user: User }

      return auth.user
    }
  } catch (error) {
    // TODO Handle localstorage not being available.
    console.error(error)
  }

  return null
}

export const signIn = async (
  email: string,
  password: string
): Promise<string> => {
  const { error } = await supabase().auth.signInWithPassword({
    email,
    password,
  })

  return error ? 'Failed to log in. Check email and password.' : ''
}

export const signUp = async (
  email: string,
  password: string,
  turnstileToken: string
): Promise<string> => {
  const { data, error } = await supabase().auth.signUp({
    email,
    password,
    options: {
      captchaToken: turnstileToken,
      emailRedirectTo: `${import.meta.env.VITE_BASE_URL}/welcome`,
    },
  })

  return error
    ? error.message
    : data.user?.identities?.length === 0
      ? 'Email already registered'
      : ''
}

export const logout = async (navigate: NavigateFunction) => {
  await supabase().auth.signOut()

  navigate('/', { replace: true })
}

export const resetPassword = async (email: string, turnstileToken: string) => {
  await supabase().auth.resetPasswordForEmail(email, {
    captchaToken: turnstileToken,
    redirectTo: `${import.meta.env.VITE_BASE_URL}/update-password`,
  })
}

export const updatePassword = async (newPassword: string) => {
  await supabase().auth.updateUser({ password: newPassword })
}
