import { Alert, Button, Label, Spinner, TextInput } from 'flowbite-react'
import { HiExclamationCircle } from 'react-icons/hi2'
import { HiLightBulb } from 'react-icons/hi2'
import { showToast } from 'utils/toast'
import { useState } from 'react'
import supabase from 'utils/supabase/client'

// Display name validation rules
const DISPLAY_NAME_VALIDATION = {
  MIN_LENGTH: 5,
  MAX_LENGTH: 25,
  ALLOWED_CHARS: /^[a-zA-Z0-9][-a-zA-Z0-9._\s]*[a-zA-Z0-9]$/,
  CONSECUTIVE_SYMBOLS: /[-._]{2,}/,
} as const

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

type AuthState = 'EMAIL_AND_NAME' | 'OTP'
type AuthMode = 'signin' | 'signup'

const validateDisplayName = (
  name: string
): { isValid: boolean; error: string | null } => {
  const trimmedName = name.trim()

  if (
    trimmedName.length < DISPLAY_NAME_VALIDATION.MIN_LENGTH ||
    trimmedName.length > DISPLAY_NAME_VALIDATION.MAX_LENGTH
  ) {
    return {
      isValid: false,
      error: `Display name must be between ${DISPLAY_NAME_VALIDATION.MIN_LENGTH} and ${DISPLAY_NAME_VALIDATION.MAX_LENGTH} characters`,
    }
  }

  if (name !== trimmedName) {
    return {
      isValid: false,
      error: 'Display name cannot start or end with spaces',
    }
  }

  if (!DISPLAY_NAME_VALIDATION.ALLOWED_CHARS.test(name)) {
    return {
      isValid: false,
      error:
        'Display name can only contain letters, numbers, and single symbols (- . _)',
    }
  }

  if (DISPLAY_NAME_VALIDATION.CONSECUTIVE_SYMBOLS.test(name)) {
    return {
      isValid: false,
      error: 'Display name cannot contain consecutive symbols',
    }
  }

  return { isValid: true, error: null }
}

const validateEmail = (email: string): boolean => EMAIL_REGEX.test(email)
const validateOtp = (otp: string): boolean =>
  otp.length === 6 && /^\d+$/.test(otp)

const AuthForm = ({ onSignedIn }: { onSignedIn: () => void }) => {
  const [authMode, setAuthMode] = useState<AuthMode>('signin')
  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [otp, setOtp] = useState('')
  const [authState, setAuthState] = useState<AuthState>('EMAIL_AND_NAME')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [displayNameError, setDisplayNameError] = useState<string | null>(null)

  const handleDisplayNameChange = (text: string) => {
    setDisplayName(text)
    const validation = validateDisplayName(text)
    setDisplayNameError(validation.error)
  }

  const handleGenerateDisplayName = () => {
    // This is a placeholder - you'll need to implement the actual generation logic
    const adjectives = ['Happy', 'Lucky', 'Clever', 'Bright', 'Swift']
    const nouns = ['Dolphin', 'Falcon', 'Tiger', 'Eagle', 'Wolf']
    const randomNum = Math.floor(Math.random() * 1000)
    const newName = `${adjectives[Math.floor(Math.random() * adjectives.length)]}${
      nouns[Math.floor(Math.random() * nouns.length)]
    }${randomNum}`
    handleDisplayNameChange(newName)
  }

  const handleSendOTP = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address')
      return
    }

    if (authMode === 'signup') {
      const displayNameValidation = validateDisplayName(displayName)
      if (!displayNameValidation.isValid) {
        setDisplayNameError(displayNameValidation.error)
        return
      }
    }

    setLoading(true)
    setError(null)

    try {
      const { error } = await supabase().auth.signInWithOtp({
        email,
        options: {
          data:
            authMode === 'signup' ? { display_name: displayName } : undefined,
        },
      })

      if (error) throw error

      showToast('Check your email for the verification code!', 'success')
      setAuthState('OTP')
    } catch (err) {
      setError('An error occurred. Please try again.')
      console.error('OTP request error:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyOTP = async () => {
    if (!validateOtp(otp)) {
      setError('Please enter a valid 6-digit code')
      return
    }

    setLoading(true)
    setError(null)

    try {
      const { error } = await supabase().auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      })

      if (error) throw error

      showToast('Successfully signed in!', 'success')
      onSignedIn()
    } catch (err) {
      setError('Invalid or expired code. Please try again.')
      console.error('OTP verification error:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleBackFromOtp = () => {
    setAuthState('EMAIL_AND_NAME')
    setOtp('')
    setError(null)
  }

  const switchAuthMode = () => {
    setAuthMode(authMode === 'signin' ? 'signup' : 'signin')
    setError(null)
    setDisplayNameError(null)
    setEmail('')
    setDisplayName('')
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white-500">
          {authMode === 'signup'
            ? 'Create your account'
            : 'Sign in to your account'}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {authMode === 'signup' ? (
            <>
              Already have an account?{' '}
              <button
                onClick={switchAuthMode}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Sign in
              </button>
            </>
          ) : (
            <>
              {"Don't have an account? "}
              <button
                onClick={switchAuthMode}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Create one
              </button>
            </>
          )}
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {authState === 'EMAIL_AND_NAME' ? (
          <div className="flex flex-col gap-4">
            <div>
              <Label htmlFor="email" value="Email" />
              <TextInput
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {authMode === 'signup' && (
              <div>
                <Label htmlFor="displayName" value="Display Name" />
                <div className="flex gap-2">
                  <TextInput
                    id="displayName"
                    value={displayName}
                    onChange={(e) => handleDisplayNameChange(e.target.value)}
                    required
                    className="flex-1"
                  />
                  <Button color="light" onClick={handleGenerateDisplayName}>
                    <HiLightBulb className="h-5 w-5" />
                  </Button>
                </div>
                {displayNameError && (
                  <p className="mt-1 text-sm text-red-600">
                    {displayNameError}
                  </p>
                )}
              </div>
            )}

            {error && (
              <Alert color="failure" icon={HiExclamationCircle}>
                {error}
              </Alert>
            )}

            <Button
              color="purple"
              onClick={handleSendOTP}
              disabled={loading}
              className="mt-2"
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <Spinner size="sm" />
                  <span>Sending code...</span>
                </div>
              ) : (
                'Send verification code'
              )}
            </Button>

            {authMode === 'signup' && (
              <p className="mt-4 text-center text-sm text-gray-500">
                By signing up, you agree to our{' '}
                <a
                  href="/terms"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="/privacy"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Privacy Policy
                </a>
              </p>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div>
              <Label htmlFor="otp" value="Verification Code" />
              <TextInput
                id="otp"
                value={otp}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '')
                  if (value.length <= 6) {
                    setOtp(value)
                  }
                }}
                required
                maxLength={6}
                placeholder="Enter 6-digit code"
                type="text"
                pattern="[0-9]*"
              />
            </div>

            {error && (
              <Alert color="failure" icon={HiExclamationCircle}>
                {error}
              </Alert>
            )}

            <Button
              color="purple"
              onClick={handleVerifyOTP}
              disabled={loading || otp.length !== 6}
              className="mt-2"
            >
              {loading ? (
                <div className="flex items-center gap-2">
                  <Spinner size="sm" />
                  <span>Verifying...</span>
                </div>
              ) : (
                'Verify code'
              )}
            </Button>

            <div className="flex flex-col gap-2">
              <Button color="light" onClick={handleSendOTP} disabled={loading}>
                Resend code
              </Button>
              <Button color="light" onClick={handleBackFromOtp}>
                Use different email
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AuthForm
