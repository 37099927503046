import { Alert } from 'flowbite-react'
import { getUser, getIsAuthenticated } from 'utils/auth'
import { HiInformationCircle } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import SignInForm from 'components/AuthForm'

const Profile = () => {
  const user = getUser()
  const navigate = useNavigate()

  const onSignedIn = () => {
    navigate('/profile')
  }

  if (!getIsAuthenticated()) {
    return (
      <div className="flex flex-col mt-10">
        <div className="flex justify-center">
          <Alert className="w-fit" color="info" icon={HiInformationCircle}>
            You need to be signed to view your profile.
          </Alert>
        </div>

        <SignInForm onSignedIn={onSignedIn} />
      </div>
    )
  }

  return (
    <>
      <h1 className="my-10 mb-6 text-4xl font-bold text-center">Profile</h1>
      <p className="text-center">Signed in as {user?.email}</p>
    </>
  )
}

export default Profile
