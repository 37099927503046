import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { format } from 'date-fns'
import { ImageSkeleton } from 'routes/ViewPost/components/ImageSkeleton'
import { Post } from 'types/Post.types'
import { PostPhoto } from 'types/PostPhoto'
import { showToast } from 'utils/toast'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import supabase from 'utils/supabase/client'
import TransparentButton from 'components/TransparentButton'
import VotingButtons from 'routes/ViewPost/components/VotingButtons/VotingButtons'

const ViewPost = () => {
  const navigate = useNavigate()
  const { postId } = useParams()

  const [photos, setPhotos] = useState<PostPhoto[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [post, setPost] = useState<Post | null>(null)

  useEffect(() => {
    const getData = async () => {
      if (!postId) {
        throw Error('Missing post ID')
      }

      // Get post details
      const { data: post, error: postError } = await supabase()
        .from('posts')
        .select()
        .eq('id', postId)
        .single()

      if (postError ?? !post) {
        showToast('Failed to get post.', 'error')
        console.error('Error fetching post:', postError)
        return
      }

      setPost(post)

      // Get all photos for this post
      const { data: photoData, error: photoError } = await supabase().rpc(
        'get_post_photos',
        { p_post_id: postId }
      )

      if (photoError ?? !photoData) {
        showToast('Failed to get post photos.', 'error')
        console.error('Error fetching photos:', photoError)
        return
      }

      if (photoData.length === 0) {
        showToast('No photos found for this post.', 'error')
        return
      }

      // Set photos directly from API response
      setPhotos(photoData)
      setLoading(false)
    }

    void getData()
  }, [postId])

  return (
    <div className="flex flex-col items-center w-full mx-auto max-w-7xl px-2 md:px-4 lg:px-8">
      <div className="flex flex-row justify-between mt-8 w-full">
        <TransparentButton
          className="text-gray-500"
          onClick={() => navigate(-1)}
        >{`<`}</TransparentButton>

        {post && (
          <TransparentButton
            className="text-gray-500"
            onClick={() => navigate(`/contact?type=Report&postId=${post?.id}`)}
          >
            Report
          </TransparentButton>
        )}
      </div>

      <div className="w-full mt-2 mb-4">
        {loading ? (
          <ImageSkeleton />
        ) : (
          <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={photos.length > 1}
            infiniteLoop={true}
            dynamicHeight={false}
            emulateTouch={true}
            swipeable={true}
            useKeyboardArrows={true}
            className="post-carousel"
          >
            {photos.map((photo, index) => (
              <div key={photo.id || index}>
                <img
                  src={photo.imageUrl}
                  alt={`Image ${index + 1} of ${photos.length}`}
                  className="rounded-md"
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>

      <div className="flex flex-col items-center justify-center px-4 lg:px-0 max-w-7xl w-full">
        {post && <VotingButtons postId={post.id} />}

        {post && (
          <p className="mt-10 text-center text-sm text-gray-500">
            {`Added ${format(new Date(post.inserted_at), 'MMM d, yyyy')}`}
          </p>
        )}
      </div>
    </div>
  )
}

export default ViewPost
